import { AppointmentStatus } from "../_enums/AppointmentStatus";

type ISODate = string;
type ISOTime = string;

export type TableUpdates = {
    removed: [],
    added: []
}
export type TableState = {
    updates: TableUpdates;
    stale: string;
    option: string,
}
export type AuthenticationState = {
    loggedIn: User;
    user: User
}
export type User = {
    tokenExpiry: ISODate;
    assetId: any;
    id: number,
    name: string,
    activeTenant: TenantData;
    tenantUserId: number;
    currentMessageId: number;
    photo: Asset
}

export type TenantUser = {
    user: User;
    assetId?: number;
    id: number;
    userId: number,
}

export type TenantData = {
    name: string;
    subdomain: string;
    id: number,
    role: Role;
    assetId?: number;
}

export enum Role {
    None,
    User,
    ContentEditor,
    TenantManager,
    TenantAdmin,
    JourneyUser,
    BrandAmbassador,
    ShopAssistant,
    Api
}

export type State = {
    table: TableState,
    authentication: AuthenticationState
    addModal: AddModalState
}
export type AddModalState = {
    isOpen: boolean,
    modalReference: string,
}
export type TenantOptions = {
    askSurvey: boolean;
    followUpSectionTemplateId: number,
    showCustomerVideoByDefault: boolean;
    allowPrivateCustomers: boolean;
}
export type SetupStep = {
    id: any;
    title: string,
    description: string,
    buttonText: string,
    checkUrl: string,
    actionUrl: string;
}

export type Integration = {
    setupSteps: SetupStep[];
    integrationId: number | undefined;
    name: string
    baseUri: string | URL;
    class: string;
    affectedPages: string[];
}

export type Tenant = {
    activeIntegrations?: Integration[];
    options: TenantOptions;
    name: string;
    smallId: number;
    small: Asset;
    id: number;
    new: boolean;
}

export type TenantUpdate = {
    activeIntegrations?: Integration[];
    options?: TenantOptions;
    name?: string;
    smallId?: number;
    small?: Asset;
    id: number;
    new?: boolean;
}
export enum Feature {
    Crm = 0x1,
    Call = 0x2,
    Users = 0x4,
    Tenant = 0x8,
    Shop = 0x10,
    Cta = 0x20,
    Appointments = 0x40,
    Orders = 0x80,
    Integrations = 0x100,
    Journeys = 0x200,
    Notes = 0x400,
    EmailTemplates = 0x800,
    Audit = 0x1_000,
    Reports = 0x2_000,
    Tenants = 0x4_000,
    Brand = 0x8_000,
    SectionTemplate = 0x10_000,
    Product = 0x20_000,
    Media = 0x40_000,
    Tags = 0x80_000,
    CtaMessages = 0x100_000,
}

export type AccountData = {
    id: number,
    limits?: UsageInformation,
    remaining: UsageInformation,
    features: number
}

export type UsageInformation = {
    journeys: number,
    products: number,
    assets: number,
    assetbytes: number,
    users: number,
    appointments: number,
    minutes: number,
}

export type SetupOptions = {
    createAssetSource: boolean,
    createShop: boolean,
    createDepartment: boolean,
    createService: boolean,
    assignStaff: boolean,
    addCommunications: boolean,
    enableSurvey: boolean,
    addIntegrations: string[]
}

export type Account = {
    id: number;
    billingContactId: number;
    invoiceEmails: string;
    newAccount: boolean;
    referrer: string;
    servicePlan: {
        currency: string
    }
}

export type PagingList<T> = {
    items: T[];
    count: number;
}
export type AppointmentCustomer = {
    customerId: number,
    customer?: Customer
}

type AppointmentStaff = {
    userId: number
}

enum MessageType {
    Chat,
    External,
    Note
}

export type Message = {
    content: string,
    created?: string,
    type?: MessageType
}

export enum NotificationType {
    CustomerWaiting,
    CallInProgress,
    MessageReceived,
    MissedCall
}

export type Notification = {
    id: number;
    created: string;
    updated: string;
    read: boolean;
    type: NotificationType;
    data: string;
    userId: number;
    user: User;
}

export enum TwilioCallEvent {
    Created,
    ParticipantJoined,
    ParticipantLeft,
    Ended,
    CheckoutOpened,
    DisplayChanged,
    VisibilityChanged,
    FullScreen,
    CustomerWaiting
}

export type CallActionData = {
    roomId?: number;
    event: TwilioCallEvent;
    displayHeight?: number;
    displayWidth?: number;
    screenOrientation?: string;
    visibility?: boolean;
}

export enum ParticipantStatus {
    None,
    Waiting,
    OnCall,
    Away,
    Left,
}

export type TwilioIdentity = {
    customerId?: number;
    userId?: number;
    displayName: string;
}

export type TwilioConfig = {

}

export type Participant = {
    tokenId?: number;
    customer?: Customer;
    status: ParticipantStatus;
    customerId: number;
    id: number,
    name: string,
    startTime: ISODate,
    endTime: ISODate
    userId?: number;
    user: User
}

export type VideoCall = {
    appointment?: Appointment;
    roomType?: number;
    transactionId?: number;
    notes?: Message[];
    id?: number,
    roomName: string,
    roomId?: number,
    journeyId?: number,
    start?: ISODate,
    streamStart?: ISODate;
    end?: ISODate,
    participants?: Participant[]
}

export type Appointment = {
    appointmentTemplate?: Service;
    department?: Department;
    id?: number,
    status?: AppointmentStatus,
    title: string,
    start: string,
    end: string,
    bufferBefore: string,
    bufferAfter: string,
    overrideOpeningHours?: boolean,
    customers?: AppointmentCustomer[],
    staff?: AppointmentStaff[],
    appointmentTemplateId: number,
    departmentId: number,
    notes?: Message[]
    videoCall?: VideoCall
}

export type Service = {
    duration: string;
    name: string,
    id: number;

}
export type DepartmentService = {
    departmentId?: number,
    appointmentTemplate: Service,
    appointmentTemplateId: number,
}

export type Shop = {
    openingHours: OpeningHours[];
    bufferAfterAppointment?: number;
    bufferBeforeAppointment?: number;

}
export enum AllocationStrategy {
    None,
    //First available staff member based on assigned priority
    PriorityBasis,
    //Rotate evenly among all staff members
    RoundRobin,
    //Calculate the person with the most hours available on the given day
    LeastBusy,
    //
    SquareRobin
}

export type Department = {
    openingHours: OpeningHours[];
    name: string,
    id: number;
    staff: Staff[],
    services: DepartmentService[]
    autoAllocation: AllocationStrategy
}

export type Staff = {
    id?: number
    priority: number,
    tenantUserId: number,
    tenantUser: TenantUser,
    openingHours?: OpeningHours[],
}

export type OpeningHours = {
    start: ISOTime;
    timeZone: string;
    end: ISOTime;
    dayOfTheWeek: number;

}

export type OpeningHoursException = {
    deleted: boolean;
    start: string;
    timeZone: string;
    end: string;
    status: number;
    startDate: ISODate;
    endDate: ISODate;
    dayOfTheWeek: number;
}
export type AssetLink = {
    assetId: number,
}

export type Asset = {
    id: any;
    content: string
    filename: any;
}

export type ValueChanged = { target: { value: string } }

export type JourneyOptions = {
    chatEnabled: boolean
}

export type AccessCode = {
    customerId?: number,
    code: string
}
export enum JourneyType {
    ScrollingBackground,
    FixedBackground,
    LiveStream,
    Custom,
    ProductStream,
    Commentary,
    Choreographed,
    ShowAndSell,
    OnDemand,
}
export type Journey = {
    accessCodes?: AccessCode[];
    section: Section;
    id?: number;
    options: JourneyOptions
    type: JourneyType
    name: string,
    tenantId: number
    tenant: never,
    owner?: User,
    sectionId?: number
}

export type Product = {
    id: number,
    scanData: string;
}

export type SectionProduct = {
    productId: number,
    product?: Product,
    sectionId?: number,
    section?: Section
}
export type SectionAsset = {
    assetId: number,
    asset?: Asset,
    sectionId: number,
    section?: Section
}
export type Section = {
    id?: number;
    title: string;
    subTitle: string;
    background?: Asset,
    backgroundId: number,
    products: SectionProduct[];
    relatedAssets: SectionAsset[]

}

export type CTAMessages = {
    id: number;
    userId: number;
    status: MessageStatus
    content: string;
    created: string;
}

export type Customer = {
    photo?: Asset;
    phone?: string;
    email?: string;
    name: string;
    id: number,
    messages?: CTAMessages[];
    salesAssociate?: User;
    updated?: string;
    tags?: string[]
}

export enum MessageStatus {
    Accepted,
    Scheduled,
    Queued,
    Sending,
    Sent,
    Failed,
    Delivered,
    Undelivered,
    Receiving,
    Received,
    Read,
    Deleted,
};
