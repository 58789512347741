import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './actions.scss';
import "react-toggle/style.css"
import { tenantService } from '../../_services/tenant.service';
import { accountService } from '../../_services/accounts.service';
import { shopLocationService } from '../../_services/shop-locations.service';
import { isAdmin } from '../../_helpers/user.perms';
import PricingModal from './DashboardComponents/PricingModal';
import { useNavigate } from 'react-router-dom';
import { Account, Integration, SetupStep, Shop, State, User } from '../../_types';
import { apiService } from '../../_services';
import { useTranslation } from 'react-i18next';

type Step = {
    title: string,
    description: string,
    buttonText: string,
    action: () => void
}

export function ConnectActions() {
    const { t } = useTranslation("common");
    const user = useSelector<State, User>(state => state.authentication.user);
    const [account, setAccount] = useState<Account>();
    const [showModal, setShowModal] = useState(false);
    const [completedSteps, setCompletedSteps] = useState([false, false, false]);
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();
    const [tenantId, setTenantId] = useState<number>();
    const [steps, setSteps] = useState<Step[]>([]);

    useEffect(() => {
        setSteps(s => {
            const copy = [...s];
            copy[0] = { title: "Select your plan", description: "Choose a plan that fits your business needs", buttonText: "Choose Plan", action: handleChoosePlan };
            copy[1] = { title: "Upload Your Company Logo", description: "Add your brand’s logo for a professional touch.", buttonText: "Upload Logo", action: handleUploadLogo };
            copy[2] = { title: "Set Your Store Timezone & Hours", description: "Ensure accurate scheduling by setting your timezone and business hours.", buttonText: "Set Timezone", action: handleSetTimezone };
            return copy;
        })
    }, [account, tenantId])

    useEffect(() => {
        if (user?.activeTenant?.id) {
            setTenantId(user.activeTenant.id);
        }
    }, [user]);

    async function fetchAccount() {
        try {
            const response = await accountService.getAccount();
            if (response?.items.length > 0) {
                setAccount(response.items[0]);
            }
        } catch (error) {
            console.error("Error fetching account:", error);
        }
    }

    useEffect(() => {
        fetchAccount();
    }, []);

    useEffect(() => {
        if (tenantId) {
            newFunction(tenantId);
        }

        function createStep(step: SetupStep): Step {
            return { ...step, action: () => navigate(step.actionUrl) };
        }

        async function newFunction(tenantId: number) {
            try {
                const response = await tenantService.getTenantById(tenantId, "activeIntegrations");
                if (!response)
                    return;
                if (response.smallId) {
                    completeStep(1)
                }

                let x = 3;
                if (response.activeIntegrations) {
                    for (const i of response.activeIntegrations) {
                        const integration: Integration = await apiService.getItem("integrations", i.integrationId, { include: ["setupSteps"] });
                        if (integration.setupSteps.length > 0) {
                            for (const step of integration.setupSteps) {
                                setSteps(s => [...s, createStep(step)])
                                const check = await apiService.getItem(`integrations/${i.integrationId}/setup/${step.id}`);
                                updatedCompletedSteps(x, check.result)
                                x += 1;
                            }
                        }
                    }
                }
                setLoaded(true);
            } catch (error) {
                console.error("Error fetching tenant data:", error);
            }
        }
    }, [tenantId])

    useEffect(() => {
        async function checkInitialSetup() {

            if (account && !account.newAccount) {
                completeStep(0)
            }

            try {
                const shops = await shopLocationService.getShopLocations();
                if (shops?.items?.length) {
                    const firstShopId = shops.items[0].id;
                    const shopDetails: Shop = await shopLocationService.getByid(firstShopId, {
                        include: ['openinghours']
                    });

                    if (Array.isArray(shopDetails.openingHours) && shopDetails.openingHours.some(hour => hour.timeZone)) {
                        completeStep(2)
                    }
                }
            } catch (error) {
                console.error("Error checking timezone setup:", error);
            }
        }


        checkInitialSetup();

    }, [account]);

    useEffect(() => {
        if (tenantId && completedSteps.every(step => step) && loaded) {
            tenantService.updateTenant({ id: tenantId, new: false });
        }
    }, [completedSteps, loaded])

    function updatedCompletedSteps(index: number, complete: boolean) {
        setCompletedSteps(prev => {
            const updatedSteps = [...prev];
            updatedSteps[index] = complete;
            return updatedSteps;
        });
    }

    // Function to mark a step as completed
    function completeStep(index: number) {
        updatedCompletedSteps(index, true);
    }

    function handleChoosePlan() {
        if (account) {
            if (account.newAccount) {
                setShowModal(true);
            } else {
                completeStep(0);
            }
        }
    }


    async function handleUploadLogo() {
        if (tenantId) {
            try {
                const response = await tenantService.getTenantById(tenantId, "");
                console.log("loading logo", response.smallId);

                if (!response.smallId) {
                    console.log("Opening company page to setup the logo");
                    navigate('/company');
                } else {
                    completeStep(1);
                }
            } catch (error) {
                console.error("Error fetching tenant data:", error);
                completeStep(1);
            }
        }
    }


    async function handleSetTimezone() {
        const shops = await shopLocationService.getShopLocations();

        if (!shops?.items?.length) {
            console.warn("No shop locations found.");
            return;
        }

        const firstShopId = shops.items[0].id;
        const shopDetails: Shop = await shopLocationService.getByid(firstShopId, {
            include: ['openinghours']
        });

        console.log("Shop Details:", shopDetails.openingHours);

        if (Array.isArray(shopDetails.openingHours) && shopDetails.openingHours.length > 0) {
            const hasValidOpeningHours = shopDetails.openingHours.some(hour => hour.timeZone);

            if (hasValidOpeningHours) {
                console.log("Opening hours found:", shopDetails.openingHours);
                completeStep(2);
            } else {
                console.log("Navigating to timezone settings...");
                navigate('/shop');
            }
        } else {
            console.log("Navigating to timezone settings...");
            navigate('/shop');
        }
    }

    function handleClose(free: boolean) {
        setShowModal(false);
        if (free) {
            completeStep(0);
        }
    }

    return (
        <div className='mandatory-actions-cover'>
            <div className='page-header'>
                <div className='page-title'>{t("dashboard.actions-title")}</div>
                <div className='page-subtitle'>{t("dashboard.actions-subtitle")}</div>
            </div>
            <div className='mandatory-actions'>
                {steps.map((step, index) => (
                    <div className='mandatory-action-item' key={index}>
                        <div className='mandatory-action-item-state'>
                            <div className={`circle-state ${completedSteps[index] ? 'active' : ''}`}>
                                {completedSteps[index] && <i className='fas fa-check'></i>}
                            </div>
                        </div>
                        <div className='mandatory-action-wrap'>
                            <div className='mandatory-action-text'>
                                <div className='mandatory-action-step'>{t("dashboard.step")} {index + 1}</div>
                                <div className='mandatory-action-title'>{step.title}</div>
                                <div className='mandatory-action-description'>{step.description}</div>
                            </div>
                            <div className='mandatory-action-button'>
                                {completedSteps[index] ?
                                    <div className='mandatory-action-completed'>{t("dashboard.completed")}</div>
                                    :
                                    <div className='btn btn-primary' onClick={step.action}>{step.buttonText}</div>

                                }

                            </div>
                        </div>
                    </div>
                ))}
                {isAdmin(user) && <PricingModal showModal={showModal} handleClose={handleClose} />}
            </div>
        </div>
    );
}
